import React from "react";
import esymsLogo from "../../../assets/esyms-square-logo.png";
import mobileImage from "../../../assets/dynamic/mobile-img.png";
import PageContent from "./HorizontalContent";
import styles from "../../../styles/pages/DynamicPage.module.css";

const HorizontalView = () => {
  return (
    <div className={styles.background}>
      <div className={styles.content}>
        <img src={mobileImage} className={styles.mobileImage} alt="phone" />
        <div className={styles.contentBackground}>
          <div className={styles.logoSection}>
            <img src={esymsLogo} className={styles.logo} alt="esyms-logo" />
          </div>
          <PageContent />
        </div>
      </div>
    </div>
  );
};

export default HorizontalView;

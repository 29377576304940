import React from "react";
import styles from "../../../styles/pages/DynamicPage.module.css";
import esymsLogo from "../../../assets/esyms-square-logo.png";
import mobileImage from "../../../assets/dynamic/mobile-img-with-bg.png";
import androidButton from "../../../assets/android_button.png";
import appleButton from "../../../assets/apple_button.png";

const VerticalView = () => {
  return (
    <div className={styles.backgroundMobile}>
      <div className={styles.logoVerticalSection}>
        <img src={esymsLogo} className={styles.logoVertical} alt="esyms-logo" />
      </div>
      <h1 className={styles.h1Vertical}>Pharmacy In One Click, Seamlessly</h1>
      <img
        src={mobileImage}
        className={styles.mobileImageVertical}
        alt="mobile"
      />
      <span className={styles.downloadText}>
        DOWNLOAD the NEW{" "}
        <span className={styles.downloadTextGreen}>Esyms App Now!</span>
      </span>
      <div className={styles.buttonSectionVertical}>
        <div>
          <a href="https://apps.apple.com/my/app/esyms/id6449419085">
            <img
              src={appleButton}
              className={styles.buttonVertical}
              alt="apple-button"
            />
          </a>
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.esyms.consumer">
            <img
              src={androidButton}
              className={styles.buttonVertical}
              alt="android-button"
            />
          </a>
        </div>
      </div>
      <p className={styles.contentVertical}>
        To make healthcare virtually effortless with the comfort and expertise
        of our certified pharmacists. Esyms ensures customers gain easier access
        to healthcare, wellness and personal care products. By leveraging on
        today’s technology, benefit from our on-demand pharmacy services.
      </p>
    </div>
  );
};

export default VerticalView;

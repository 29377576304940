import React from "react";
import styles from "../../styles/pages/DynamicPage.module.css";

type DynamicPageContainerProps = {
  children: JSX.Element;
};

const DynamicPageContainer = ({ children }: DynamicPageContainerProps) => {
  return <div className={styles.container}>{children}</div>;
};

export default DynamicPageContainer;

import React from "react";
import styles from "../../../styles/pages/DynamicPage.module.css";
import androidButton from "../../../assets/android_button.png";
import appleButton from "../../../assets/apple_button.png";

const PageContent = () => {
  return (
    <div className={styles.contentPage}>
      <h1 className={styles.h1}>Pharmacy In One Click, Seamlessly</h1>
      <h2 className={styles.h2}>DOWNLOAD the NEW Esyms App Now!</h2>
      <p className={styles.paragraph}>
        To make healthcare virtually effortless with the comfort and expertise
        of our certified pharmacists. Esyms ensures customers gain easier access
        to healthcare, wellness and personal care products. By leveraging on
        today’s technology, benefit from our on-demand pharmacy services.
      </p>
      <div className={styles.buttonSection}>
        <a href="https://apps.apple.com/my/app/esyms/id6449419085">
          <img src={appleButton} className={styles.button} alt="apple-button" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.esyms.consumer">
          <img
            src={androidButton}
            className={styles.button}
            alt="android-button"
          />
        </a>
      </div>
    </div>
  );
};

export default PageContent;

import React from 'react';
import DynamicPageContainer from './Container';
import HorizontalView from './Horizontal';
import VerticalView from './Vertical';

const DynamicPage = () => {
  const displayHorizontal =
    window.innerWidth >= window.innerHeight || window.innerWidth > 768;

  const DynamicView = displayHorizontal ? HorizontalView : VerticalView;

  return (
    <DynamicPageContainer>
      <DynamicView />
    </DynamicPageContainer>
  );
};

export default DynamicPage;
